import styles from './styles.module.css'
import ActionButton from '../../components/ActionButton'
import {useHistory} from 'react-router-dom'

const Timeout = () => {
  const history = useHistory()

  return (
    <div className={styles.page}>
      <div className={styles.message}>
        Your session expired
      </div>
      <ActionButton
        text="Start booking again"
        onClick={() => history.push('/product')}
      />
    </div>
  )
}

export default Timeout
