import {Field} from "./Fields"

type Action =
  { type: 'setField', fieldName: string, field: Field } |
  { type: 'highlight' }

export class InputState {
  public readonly isValid: boolean

  constructor(
    public readonly field: Field,
    public readonly isHighlighted: boolean = false,
  ) {
    this.isValid = field.validate()
  }
}

interface IInputs {
  [fieldName: string]: InputState
}

export class ContactState {
  public readonly isValid: boolean
  public readonly isFilled: boolean
  public readonly inputs: IInputs

  constructor(inputs: IInputs) {
    this.inputs = inputs
    this.isValid = Object.keys(this.inputs).every((fieldName) => this.inputs[fieldName].isValid)
    this.isFilled = Object.keys(this.inputs).every((fieldName) => this.inputs[fieldName].field.value)
  }
}

export function contactReducer(contactState: ContactState, action: Action): ContactState {
  if (action.type === "setField") {
    return new ContactState({
      ...contactState.inputs,
      [action.fieldName]: new InputState(action.field),
    })
  }

  if (action.type === 'highlight') {
    const inputs: IInputs = {}

    for (const fieldName of Object.keys(contactState.inputs)) {
      const input = contactState.inputs[fieldName]
      inputs[fieldName] = new InputState(input.field, !input.isValid)
    }

    return new ContactState(inputs)
  }

  throw new Error('Incorrect action type')
}
