import React from 'react'
import styles from './styles.module.css'

interface IProps {
  children: any
}

function Paragraph({children}: IProps) {
  return <div className={styles.rules}>{children}</div>
}

export default Paragraph
