import React from 'react'
import styles from './styles.module.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons"
import cx from "classnames"

interface IProps {
  title?: string
  className?: string
  onClick: () => void
  direction: 'left' | 'right'
}

function CalendarNavigation({title, direction, className, onClick}: IProps) {
  return (
    <div
      className={cx(styles.calendarNavigation, className)}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={direction === 'left' ? faChevronLeft : faChevronRight}/>
      {title && <div>{title}</div>}
    </div>
  )
}

export default CalendarNavigation
