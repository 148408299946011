import React from "react"
import styles from './styles.module.css'

interface IProps {
  children: React.ReactNode
}

export const Info = ({children}: IProps) => {
  return (
    <div className={styles.helper}>
      <div className={styles.info}>
        {children}
      </div>
    </div>
  )
}
