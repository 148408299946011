import React, {useEffect, useState} from 'react'
import styles from './styles.module.css'
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js'
import {StripeCardElement, StripeCardElementChangeEvent} from '@stripe/stripe-js'
import {BillingInfo, IBillingInfo} from "../BillingAddress"
import {BillingAddress, Contact, Settings} from '../../commonTypes'

export interface ICardInput {
  stripeToken: string
  billingAddress: BillingAddress | null
}

interface ICheckoutProps {
  settings: Settings
  contact: Contact
  disabled: boolean
  onCreateTokenError: (error: any) => void
  onCardInputChange: (input: ICardInput | null) => void
}

export function Checkout({
                           settings,
                           contact,
                           disabled,
                           onCreateTokenError,
                           onCardInputChange,
                         }: ICheckoutProps) {
  const stripe = useStripe()
  const elements = useElements()
  const [stripeTokenIsComplete, setStripeTokenIsComplete] = useState(false)
  const [billingInfo, setBillingInfo] = useState<IBillingInfo>({billingAddress: null, isComplete: false})
  const inputIsComplete = stripeTokenIsComplete && (billingInfo.isComplete || !settings.collectBillingAddress)

  // TODO: create token on Pay button click
  useEffect(() => {
      (async () => {
        if (!stripe) {
          return
        }

        if (!inputIsComplete) {
          onCardInputChange(null)
          return
        }

        const card = elements!.getElement(CardElement) as StripeCardElement

        const stripeBillingAddress = billingInfo.billingAddress
          ? {
            address_line1: billingInfo.billingAddress.addressLine1,
            address_line2: billingInfo.billingAddress.addressLine2,
            city: billingInfo.billingAddress.city,
            state: billingInfo.billingAddress.state,
            country: billingInfo.billingAddress.country,
          }
          : {}

        const {error: createTokenError, token} = await stripe.createToken(card, {
          name: `${contact.firstName} ${contact.lastName}`,
          ...stripeBillingAddress,
        })

        if (createTokenError) {
          onCreateTokenError(createTokenError)
          return
        }

        if (!token) {
          throw new Error(`Stripe token is not generated`)
        }

        onCardInputChange({
          stripeToken: token!.id,
          billingAddress: billingInfo.billingAddress,
        })
      })()
    },
    [
      contact,
      elements,
      billingInfo,
      inputIsComplete,
      onCardInputChange,
      onCreateTokenError,
      stripe,
    ],
  )

  const onChangeCardData = async (event: StripeCardElementChangeEvent) => {
    setStripeTokenIsComplete(event.complete)
  }

  return (
    <form className="w-100 d-flex flex-column gap-2">
      <div>
        <h5 className="fw-bold">Card Details</h5>
        <div className={styles.card}>
          <CardElement
            onChange={onChangeCardData}
            options={{
              hidePostalCode: !settings.collectPostalCode,
              disabled,
              iconStyle: "solid",
              style: {
                base: {
                  backgroundColor: '#5469d4',
                  iconColor: "#c4f0ff",
                  color: "#fff",
                  fontWeight: 500,
                  fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                  fontSize: "16px",
                  fontSmoothing: "antialiased",
                  ":-webkit-autofill": {
                    color: "#fce883",
                  },
                  "::placeholder": {
                    color: "#87bbfd",
                  },
                },
                invalid: {
                  iconColor: "#ffc7ee",
                  color: "#ffc7ee",
                },
              },
            }}
          />
        </div>
      </div>
      {settings.collectBillingAddress && (
        <>
          <h5 className="fw-bold">Billing Address</h5>
          <BillingInfo onChange={setBillingInfo} />
        </>
      )}
    </form>
  )
}

export default Checkout
