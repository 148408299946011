import React, {useCallback, useState} from 'react'
import {useHistory} from "react-router-dom"
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import {Checkout, ICardInput} from "../../components/Checkout"
import ShoppingCard from "../../components/ShoppingCard"
import styles from "../Payment/styles.module.css"
import EditLink from "../../components/EditLink"
import UserInfo from "../../components/UserInfo"
import api from "../../api"
import ActionButton from "../../components/ActionButton"
import Spinner from "../../components/Spinner"
import axios, {AxiosError} from "axios"
import Rules from "../../components/Rules"
import cx from "classnames"
import TravelersInfo from '../../components/TravelersInfo'
import {
  useBookingInfo,
  useContact,
  useInput,
  useProduct,
  useResultSetter,
  useSettings,
  useTravelers,
} from '../../BookingWidgetProvider/context'
import { getCurrencySymbol } from 'common-utils'
import {IResponse, IServiceError} from "../../commonTypes"
import {Timer} from '../../components/Timer'

const splitName = (name: string) => {
  return name
    .replace(/[-.,_]/g, '')
    .replace(/\s+/g, ' ')
    .trim()
    .toLowerCase()
    .split(' ')
}

const checkSign = (customerName: string, sign: string) => {
  const originalParts = splitName(customerName)
  const signParts = splitName(sign)

  for (const signPart of signParts) {
    if (!originalParts.includes(signPart)) {
      return false
    }
  }

  return true
}

function Payment() {
  const history = useHistory()

  const settings = useSettings()
  const contact = useContact()
  const product = useProduct()
  const input = useInput()
  const bookingInfo = useBookingInfo()
  const travelers = useTravelers()
  const setResult = useResultSetter()

  const [paying, setPaying] = useState(false)
  const [cardInput, setCardInput] = useState<ICardInput | null>(null)
  const [error, setError] = useState('')
  const [sign, setSign] = useState('')

  const saveResult = (bookingUuid: string) => {
    setResult({ bookingUuid })
    history.push('/result')
  }

  const onError = useCallback((error: IServiceError) => {
    return history.push(`/error?errorMessage=${error.message}`)
  }, [history])

  const makeBooking = async () => {
    if (!cardInput) {
      throw new Error(`Card input is not completed`)
    }

    const data = await api.confirmBooking({
      bookingUuid: bookingInfo.bookingUuid,
      email: contact.email,
      phone: contact.phone,
      firstName: contact.firstName,
      lastName: contact.lastName,
      productId: input.productId,
      optionId: input.optionId,
      configCode: input.configCode,
      availabilityId: product.availabilityId,
      units: product.units,
      stripeToken: cardInput.stripeToken,
      billingAddress: cardInput.billingAddress,
      travelers: travelers || [],
    })

    return data.booking
  }

  const tryMakeBooking = async () => {

    setPaying(true)

    try {
      const booking = await makeBooking()
      saveResult(booking.uuid)
    } catch (error) {
      console.dir(error, {depth: null})
      const serviceError = axios.isAxiosError(error)
        ? (error as AxiosError<IResponse>).response?.data?.error || {message: 'Unknown Server Error', code: 'unknown'}
        : {message: (error as Error).message || 'Unknown Error', code: 'unknown'}

      setCardInput(null)

      if (serviceError.code === 'payment_error') {
          return setError(serviceError.message)
      }

      onError(serviceError)
    } finally {
      setPaying(false)
    }
  }

  const customerName = `${contact.firstName} ${contact.lastName}`

  const signIsCorrect = checkSign(customerName, sign)

  const stripePromise = loadStripe(settings.stripePublicApiKey)

  return (
    <div className={styles.page}>
      <Timer
        className={styles.timer}
        utcCreatedAt={bookingInfo.utcCreatedAt}
      />
      <div className={styles.input}>
        <div className={styles.header}>
          <h5 className="fw-bold mb-0">Shopping Cart</h5>
          <EditLink path="/product"/>
        </div>

        <ShoppingCard product={product}/>

        <div className={styles.header}>
          <h5 className="fw-bold mb-0">Contact Information</h5>
          <EditLink path="/contact"/>
        </div>

        <UserInfo contact={contact}/>

        {settings.collectTravelersInfo && (
          <>
            <div className={styles.header}>
              <h5 className="fw-bold mb-0">Travelers</h5>
              <EditLink path="/travelers"/>
            </div>

            <TravelersInfo travelers={travelers!}/>
          </>
        )}

        <div className="alert alert-primary mb-0 d-flex flex-column gap-3">
          <Rules>
            <div>Please enter your card information to reserve your spot on the tour.</div>
            <div>Your card will be checked for validity, but you will not be charged as long as you show up with a valid
              pass at the tour check in.
            </div>
          </Rules>
        </div>

        <Elements stripe={stripePromise}>
          <Checkout
            settings={settings}
            contact={contact}
            onCardInputChange={setCardInput}
            onCreateTokenError={onError}
            disabled={paying}
          />
        </Elements>

        <div className="alert alert-primary mb-0">
          <Rules>
            <div>Please type in your name to certify that you understand the following:</div>
            <div>1. I have 24 hours before the start of the tour to cancel or reschedule without any obligations. Within
              24 hours, reservations are considered confirmed, and I am expected to show up with a valid pass.
            </div>
            <div>2. My credit card will be charged the full price of the tour if I do not show up with a valid Pass.
            </div>
            <div>Type your name ({customerName}):</div>
          </Rules>
          <input
            className={cx("form-control mt-1", styles.sign)}
            type="text"
            placeholder="Type your name here"
            onChange={(event) => setSign(event.target.value)}
            value={sign}
          />
        </div>

        {error && (
          <div className="alert alert-warning mb-0">
            Please try a different payment card or try again later
          </div>
        )}

        {paying && <Spinner/>}
      </div>

      <ActionButton
        text={`Reserve Now (${getCurrencySymbol(settings.currency)}0)`}
        onClick={() => tryMakeBooking()}
        disabled={paying || !cardInput || !signIsCorrect}
      />
    </div>
  )
}

export default Payment
