import React from 'react'
import styles from './styles.module.css'

function Spinner() {
  return (
    <>
      <div className="modal-backdrop show"/>
      <div className="modal fade show d-flex justify-content-center align-items-center">
        <div className={styles['lds-ellipsis']}>
          <div/>
          <div/>
          <div/>
          <div/>
        </div>
      </div>
    </>
  )
}

export default Spinner
