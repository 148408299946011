import React from 'react'
import {Info} from "../Info"
import {Contact} from "../../commonTypes"

interface IProps {
  contact: Contact
}

function UserInfo({contact}: IProps) {
  return (
    <Info>
      <div>{contact.firstName} {contact.lastName}</div>
      <div>{contact.email}</div>
      <div>{contact.phone}</div>
    </Info>
  )
}

export default UserInfo
