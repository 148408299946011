import React from 'react'
import styles from './styles.module.css'
import moment from "moment"

interface IProps {
  month: number
  year: number
}

function CalendarNavigationList({month, year}: IProps) {
  return (
    <div
      className={styles.calendarNavigationList}
    >
      {moment({month, year}).format("MMMM YYYY")}
    </div>
  )
}

export default CalendarNavigationList
