import {AppContextValue, AppState, AppStateSetters} from "./types"
import {useContext} from "react"
import {AppContext} from "./provider"
import {BookingInfo, Contact, Input, Product, Result, Settings, Traveler} from '../commonTypes'

export const useAppContext = (): AppContextValue => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error(`Booking widget context is null`)
  }

  return context
}

const useAppStateSetter = (property: keyof AppStateSetters) => {
  return useAppContext().setters[property]
}

export const useSettingsSetter = () => {
  return useAppStateSetter('setSettings') as (data: Settings) => void
}

export const useInputSetter = () => {
  return useAppStateSetter('setInput') as (data: Input) => void
}

export const useProductSetter = () => {
  return useAppStateSetter('setProduct') as (data: Product) => void
}

export const useContactSetter = () => {
  return useAppStateSetter('setContact') as (data: Contact) => void
}

export const useTravelersSetter = () => {
  return useAppStateSetter('setTravelers') as (data: Traveler[]) => void
}

export const useResultSetter = () => {
  return useAppStateSetter('setResult') as (data: Result) => void
}

export const useCountryCodeSetter = () => {
  return useAppStateSetter('setCountryCode') as (data: string) => void
}

export const useBookingInfoSetter = () => {
  return useAppStateSetter('setBookingInfo') as (data: BookingInfo) => void
}

const useAppStateDate = (property: keyof AppState) => {
  const data = useAppContext().state[property]

  if (data === undefined) {
    throw new Error(`App state property '${property}' is not defined`)
  }

  return data
}

export const useSettings = () => {
  return useAppStateDate('settings') as Settings
}

export const useInput = () => {
  return useAppStateDate('input') as Input
}

export const useProduct = () => {
  return useAppStateDate('product') as Product
}

export const useContact = () => {
  return useAppStateDate('contact') as Contact
}

export const useTravelers = () => {
  return useAppStateDate('travelers') as Traveler[]
}

export const useResult = () => {
  return useAppStateDate('result') as Result
}

export const useCountryCode = () => {
  return useAppStateDate('countryCode') as string
}

export const useBookingInfo = () => {
  return useAppStateDate('bookingInfo') as BookingInfo
}
