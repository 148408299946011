import React from 'react'
import styles from './styles.module.css'
import moment from "moment"
import cx from "classnames"
import {Entities} from "ventrata-utils"
import {AVAILABILITY_STATUSES_UNION} from "ventrata-utils/dist/declares/consts"

interface IProps {
  availability: Entities.Availability
  chosenAvailabilityId: string | null
  onChange: (availabilityId: string) => void
}

interface IStatusDescription {
  title: string
  style: string
}

const AVAILABILITY_DESCRIPTORS: { [key in AVAILABILITY_STATUSES_UNION]: IStatusDescription } = {
  SOLD_OUT: {title: 'Sold out', style: 'danger'},
  CLOSED: {title: 'Closed', style: 'danger'},
  AVAILABLE: {title: 'Available', style: 'success'},
  FREESALE: {title: 'Available', style: 'success'},
  LIMITED: {title: 'Limited', style: 'warning'},
}

function Availability({availability, chosenAvailabilityId, onChange}: IProps) {
  const statusDescription = AVAILABILITY_DESCRIPTORS[availability.status!] || {
    title: '-',
    style: 'warning',
  }

  return (
    <div
      className={cx(styles.availability, {[styles.disabled]: !availability.available})}
      onClick={(event) => onChange(availability.id)}
    >
      <input
        className="form-check-input"
        type="radio"
        checked={availability.id === chosenAvailabilityId}
        readOnly
      />
      <div className={styles.info}>
        <div className={styles.time}>{moment.parseZone(availability.id).format('hh:mm A')}</div>
        <div className={cx(styles.status, `text-${statusDescription.style}`)}>
          {statusDescription.title}
        </div>
      </div>
    </div>
  )
}

export default Availability
