import React, {useReducer} from 'react'
import {useHistory} from 'react-router-dom'
import styles from './styles.module.css'
import ShoppingCard from "../../components/ShoppingCard"
import EditLink from "../../components/EditLink"
import ActionButton from "../../components/ActionButton"
import UserInfo from "../../components/UserInfo"
import Label from "../../components/Label"
import {InputState} from "../Contact/contactReducer"
import {AgeField, NameField} from "../Contact/Fields"
import {IAllInputs, travelersReducer, TravelersState} from "./TravelersReducer"
import InputBlock from "../../components/InputBlock"
import {useBookingInfo, useContact, useProduct, useTravelersSetter} from '../../BookingWidgetProvider/context'
import {Contact, Product} from "../../commonTypes"
import {Timer} from '../../components/Timer'

const initializeTravelers = (product: Product, contact: Contact) => {
  const travelersNumber = product?.units?.reduce((result, {quantity}) => result + quantity, 0) || 0

  const inputs: IAllInputs = {
    0: {
      firstName: new InputState(NameField.new(contact.firstName)),
      lastName: new InputState(NameField.new(contact.lastName)),
      age: new InputState(AgeField.new()),
    },
  }

  for (let index = 1; index < travelersNumber; index++) {
    inputs[index] = {
      firstName: new InputState(NameField.new()),
      lastName: new InputState(NameField.new()),
      age: new InputState(AgeField.new()),
    }
  }

  return new TravelersState(inputs)
}

function Travelers() {
  const history = useHistory()
  const product = useProduct()
  const contact = useContact()
  const bookingInfo = useBookingInfo()
  const setTravelers = useTravelersSetter()

  const [travelersState, dispatch] = useReducer(travelersReducer, initializeTravelers(product, contact))
  const nextButtonEnabled = travelersState.isFilled

  const saveTravelersInfo = () => {

    if (!travelersState.isValid) {
      dispatch({type: 'highlight'})
      return
    }

    const travelers = []

    for (const traveler of Object.values(travelersState.inputs)) {
      travelers.push({
        firstName: traveler.firstName.field.normalize(),
        lastName: traveler.lastName.field.normalize(),
        age: parseInt(traveler.age.field.normalize(), 10),
      })
    }

    setTravelers(travelers)
    history.push('/payment')
  }

  return (
    <div className={styles.page}>
      <Timer
        className={styles.timer}
        utcCreatedAt={bookingInfo.utcCreatedAt}
      />
      <div className={styles.input}>
        <div className={styles.header}>
          <h5 className="fw-bold mb-0">Shopping Cart</h5>
          <EditLink path="/product"/>
        </div>

        <ShoppingCard product={product}/>

        <div className={styles.header}>
          <h5 className="fw-bold mb-0">Contact Information</h5>
          <EditLink path="/contact"/>
        </div>

        <UserInfo contact={contact!}/>

        {Object.entries(travelersState.inputs).map(([index, traveler]) => {
          return (
            <div key={index}>
              <h5 className="fw-bold">Traveler {parseInt(index, 10) + 1}</h5>
              <div className={styles.travelers}>
                <Label title="First Name" required/>
                <InputBlock
                  inputState={traveler.firstName}
                  onChange={(firstName) => dispatch({
                    type: 'setField',
                    fieldName: 'firstName',
                    index,
                    field: NameField.new(firstName),
                  })}
                />

                <Label title="Last Name" required/>
                <InputBlock
                  inputState={traveler.lastName}
                  onChange={(lastName) => dispatch({
                    type: 'setField',
                    fieldName: 'lastName',
                    index,
                    field: NameField.new(lastName),
                  })}
                />

                <Label title="Age" required/>
                <InputBlock
                  inputState={traveler.age}
                  onChange={(age) => dispatch({type: 'setField', fieldName: 'age', index, field: AgeField.new(age)})}
                />
              </div>

            </div>
          )
        })}
      </div>
      <ActionButton
        text="Proceed"
        onClick={saveTravelersInfo}
        disabled={!nextButtonEnabled}
      />
    </div>
  )
}

export default Travelers
