import React, {useReducer, useState} from 'react'
import {useHistory} from 'react-router-dom'
import styles from './styles.module.css'
import InputBlock from "../../components/InputBlock"
import ShoppingCard from "../../components/ShoppingCard"
import EditLink from "../../components/EditLink"
import ActionButton from "../../components/ActionButton"
import {contactReducer, ContactState, InputState} from "./contactReducer"
import {ConfirmedEmailField, EmailField, NameField, PhoneField} from "./Fields"
import PhoneInput from "../../components/PhoneInput"
import {getEmptyPhone} from "../../components/PhoneInput/utils"
import {
  useBookingInfo,
  useContactSetter,
  useCountryCodeSetter,
  useProduct,
  useSettings,
  useTravelersSetter,
} from '../../BookingWidgetProvider/context'
import {useQuery} from "react-query"
import api from "../../api"
import {AxiosError} from "axios"
import Spinner from "../../components/Spinner"
import {IGetCountryCodeResponse} from "../../commonTypes"
import {Timer} from '../../components/Timer'

function Contact() {
  const history = useHistory()
  const settings = useSettings()
  const product = useProduct()
  const bookingInfo = useBookingInfo()
  const setContact = useContactSetter()
  const setTravelers = useTravelersSetter()
  const countryCodeSetter = useCountryCodeSetter()
  const [countryCode, setCountryCode] = useState('')

  const {
    isFetching: isCountryCodeLoading,
  } = useQuery<IGetCountryCodeResponse, AxiosError>('getCountryCode', () => api.getCountryCode(), {
    onSuccess(data) {
      setCountryCode(data.countryCode)
      countryCodeSetter(data.countryCode)
    },
    onError() {
      setCountryCode('')
      countryCodeSetter('')
    }
  })

  const phone = getEmptyPhone(countryCode)

  const [contactState, dispatch] = useReducer(contactReducer, new ContactState({
    firstName: new InputState(NameField.new()),
    lastName: new InputState(NameField.new()),
    email: new InputState(EmailField.new()),
    confirmedEmail: new InputState(ConfirmedEmailField.new()),
    phone: new InputState(PhoneField.new(phone)),
  }))

  const nextButtonEnabled = contactState.isFilled

  const saveContact = () => {
    if (!contactState.isValid) {
      dispatch({type: 'highlight'})
      return
    }

    setContact({
      email: contactState.inputs.email.field.normalize(),
      phone: contactState.inputs.phone.field.normalize(),
      firstName: contactState.inputs.firstName.field.normalize(),
      lastName: contactState.inputs.lastName.field.normalize(),
    })

    if (!settings.collectTravelersInfo) {
      setTravelers([])
    }

    settings.collectTravelersInfo
      ? history.push('/travelers')
      : history.push('/payment')
  }

  if (isCountryCodeLoading) {
    return <Spinner/>
  }

  return (
    <div className={styles.page}>
      <Timer
        className={styles.timer}
        utcCreatedAt={bookingInfo.utcCreatedAt}
      />
      <div className={styles.input}>
        <div className={styles.header}>
          <h5 className="fw-bold mb-0">Shopping Cart</h5>
          <EditLink path="/product"/>
        </div>

        <ShoppingCard product={product}/>
        <h5 className="fw-bold">Contact Information</h5>
        <InputBlock
          label="First Name"
          inputState={contactState.inputs.firstName}
          onChange={(firstName) => dispatch({
            type: 'setField',
            fieldName: 'firstName',
            field: NameField.new(firstName),
          })}
        />
        <InputBlock
          label="Last Name"
          inputState={contactState.inputs.lastName}
          onChange={(lastName) => dispatch({
            type: 'setField',
            fieldName: 'lastName',
            field: NameField.new(lastName),
          })}
        />
        <InputBlock
          label="Email"
          inputState={contactState.inputs.email}
          onChange={(email) => {
            dispatch({
              type: 'setField',
              fieldName: 'email',
              field: EmailField.new(email),
            })
            dispatch({
              type: 'setField',
              fieldName: 'confirmedEmail',
              field: ConfirmedEmailField.new(email, contactState.inputs.confirmedEmail.field.value),
            })
          }}
        />
        <InputBlock
          label="Confirm Email"
          inputState={contactState.inputs.confirmedEmail}
          onChange={(confirmedEmail) => dispatch({
            type: 'setField',
            fieldName: 'confirmedEmail',
            field: ConfirmedEmailField.new(contactState.inputs.email.field.value, confirmedEmail),
          })}
        />
        <InputBlock
          component={PhoneInput}
          label="Mobile"
          inputState={contactState.inputs.phone}
          onChange={(phone) => dispatch({
            type: 'setField',
            fieldName: 'phone',
            field: PhoneField.new(phone),
          })}
        />
      </div>
      <ActionButton
        text="Proceed"
        onClick={saveContact}
        disabled={!nextButtonEnabled}
      />
    </div>
  )
}

export default Contact
