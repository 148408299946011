import React, {useReducer, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {isEmpty} from 'lodash'
import styles from './styles.module.css'
import TourTitle from "../../components/TourTitle"
import api from "../../api"
import {useQuery} from "react-query"
import {AxiosError} from "axios"
import AgeUnit from "../../components/AgeUnit"
import Acceptance from "../../components/Acceptance"
import ActionButton from "../../components/ActionButton"
import Calendar, {ICalendarDate} from "../../components/Calendar"
import {unitsReducer} from "./unitsReducer"
import moment from "moment"
import Availabilities from "../../components/Availabilities"
import ErrorMessage from "../../components/ErrorMessage"
import {Entities, getOptionName, getProductName, getUnitMapper} from "ventrata-utils"
import Spinner from "../../components/Spinner"
import {useBookingInfoSetter, useInput, useProductSetter} from '../../BookingWidgetProvider/context'
import {IGetAvailabilitiesResponse, IGetCalendarResponse, IGetProductResponse} from "../../commonTypes"
import {useCreateBookingMutation} from '../../queries/useCreateBookingMutation'

function Product() {
  const history = useHistory()
  const input = useInput()
  const changeProduct = useProductSetter()
  const setBookingInfo = useBookingInfoSetter()
  const now = moment()

  const [processingError, setProcessingError] = useState('')
  const [product, setProduct] = useState<Entities.Product | null>(null)
  const [option, setOption] = useState<Entities.Option | null>(null)
  const [calendarDate, setCalendarDate] = useState<ICalendarDate>({
    year: now.year(),
    month: now.month(),
    date: 0,
  })
  const [units, dispatch] = useReducer(unitsReducer, [])
  const [calendar, setCalendar] = useState<Entities.CalendarDay[] | null>(null)
  const [availabilities, setAvailabilities] = useState<Entities.Availability[] | null>(null)
  const [chosenAvailabilityId, chooseAvailabilityId] = useState<string | null>(null)
  const [accepted, setAccepted] = useState(false)

  const createBookingMutation = useCreateBookingMutation()

  const addToCard = () => {
    if (!product) {
      throw new Error('Product is null')
    }

    if (!option) {
      throw new Error('Option is null')
    }

    if (!chosenAvailabilityId) {
      throw new Error('Availability is null')
    }

    createBookingMutation.mutate({
      configCode: input.configCode,
      productId: input.productId,
      optionId: input.optionId,
      units: units,
      availabilityId: chosenAvailabilityId,
    }, {
      onSuccess({bookingInfo}) {
        setBookingInfo(bookingInfo)

        changeProduct({
          productName: getProductName(product),
          optionName: getOptionName(option),
          availabilityId: chosenAvailabilityId,
          units,
          unitMapper: getUnitMapper(product.options[0].units),
        })

        history.push('/contact')
      }
    })
  }

  const {productId, optionId, configCode} = input

  const {
    isFetching: isProductLoading,
    error: productError,
  } = useQuery<IGetProductResponse, AxiosError>(['getProduct', productId], () => api.getProduct(
      {
        configCode: configCode!,
        productId: productId!,
      }),
    {
      enabled: Boolean(productId),
      onSuccess: (productData) => {
        const product = productData.product
        const option = product.options.find((option: Entities.Option) => option.id === optionId)

        if (!option) {
          return setProcessingError(`Unknown option id '${optionId}'`)
        }

        const firstUnit = option.units[0]
        setProduct(product)
        setOption(option)
        dispatch({
          type: 'initialize',
          counters: [
            {id: firstUnit.id, type: firstUnit.type, quantity: 1},
            ...option.units.slice(1).map((unit: Entities.Unit) => ({id: unit.id, type: unit.type, quantity: 0})),
          ],
        })
      },
    },
  )

  const {
    isFetching: isCalendarLoading,
    error: calendarError,
  } = useQuery<IGetCalendarResponse, AxiosError>(['getCalendar', units, calendarDate.month, calendarDate.year], () => api.getCalendar(
      {
        month: calendarDate.month,
        year: calendarDate.year,
        units,
        productId: productId,
        optionId: optionId,
        configCode: configCode,
      }),
    {
      enabled: !isEmpty(units),
      onSuccess: (calendarData) => {
        setCalendar(calendarData.calendar)
      },
    },
  )

  const {
    isFetching: isAvailabilityLoading,
    error: availabilityError,
  } = useQuery<IGetAvailabilitiesResponse, AxiosError>(['getAvailabilities', units, calendarDate], () => api.getAvailabilities(
      {
        month: calendarDate.month,
        year: calendarDate.year,
        date: calendarDate.date!,
        units,
        productId,
        optionId,
        configCode,
      }),
    {
      enabled: calendarDate.date !== 0,
      onSuccess: (availabilitiesData) => {
        setAvailabilities(availabilitiesData.availabilities)
        chooseAvailabilityId(null)
      },
    },
  )

  const loading = isProductLoading || isCalendarLoading || isAvailabilityLoading || createBookingMutation.isLoading
  const error = productError || calendarError || availabilityError || processingError || createBookingMutation.error

  const nextButtonEnabled =
    calendarDate
    && chosenAvailabilityId
    && accepted
    && units.filter((unit) => unit.quantity > 0).length > 0

  return (
    <div className={styles.page}>
      <div className={styles.input}>
        {calendar && (
          <>
            <TourTitle title={getProductName(product!)}/>
            {option!.units.map((unit, i) => {
              return (
                <AgeUnit
                  key={unit.type}
                  unit={unit}
                  onQuantityChange={(quantity) => dispatch({
                    type: 'setUnit',
                    counter: {id: unit.id, type: unit.type, quantity},
                  })}
                  initialQuantity={i === 0 ? 1 : 0}
                />
              )
            })}
            <Calendar
              days={calendar}
              calendarDate={calendarDate}
              onChangeCalendarDate={setCalendarDate}
            />
            {availabilities && (
              <Availabilities
                availabilities={availabilities}
                chosenAvailabilityId={chosenAvailabilityId}
                onChange={(availabilityId) => chooseAvailabilityId(availabilityId)}
              />
            )}
            <Acceptance accepted={accepted} onChange={(value) => setAccepted(value)}/>
          </>
        )}

      </div>
      {error && (
        <ErrorMessage className={styles.error} error={error}/>
      )}
      {calendar && (
        <ActionButton
          text="Add to Cart"
          onClick={addToCard}
          disabled={!nextButtonEnabled}
        />
      )}
      {loading && (
        <Spinner/>
      )}
    </div>
  )
}

export default Product
