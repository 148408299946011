import React from 'react'
import styles from './styles.module.css'
import moment from "moment"
import {capitalize, countBy} from 'lodash'
import {DEFAULT_OPTION, Entities, getOptionName, getProductName} from "ventrata-utils"

interface IProps {
  booking: Entities.Booking
}

function BookingInfo({booking}: IProps) {
  const unitsCounters = countBy(booking.unitItems, (item) => item.unit.type)
  const units = Object.keys(unitsCounters).map((type) => ({type, quantity: unitsCounters[type]}))
  const productName = getProductName(booking.product)
  const optionName = getOptionName(booking.option)

  return (
    <div className="card">
      <div className="card-body">
        <div>{productName}</div>
        {optionName !== DEFAULT_OPTION && <div>{optionName}</div>}
        <div className={styles.date}>{moment.parseZone(booking.availabilityId).format('MMM DD, YYYY hh:mm A')}</div>
        <div className={styles.units}>
          {units
            .filter((unit) => unit.quantity !== 0)
            .map((unit) => <div key={unit.type}>{unit.quantity} {capitalize(unit.type)}</div>)}
        </div>
        <div>{booking.contact.firstName} {booking.contact.lastName}</div>
        <div>{booking.contact.emailAddress}</div>
        <div>{booking.contact.phoneNumber}</div>
      </div>
    </div>
  )
}

export default BookingInfo
