import React from 'react'
import styles from './styles.module.css'
import Availability from "../Availability"
import {Entities} from "ventrata-utils"

interface IProps {
  availabilities: Entities.Availability[]
  chosenAvailabilityId: string | null
  onChange: (chosenAvailabilityId: string) => void
}

function Availabilities({availabilities, chosenAvailabilityId, onChange}: IProps) {
  return (
    <div className={styles.availabilities}>
      {availabilities.map((availability) => {
        return (
          <Availability
            key={availability.id}
            availability={availability}
            chosenAvailabilityId={chosenAvailabilityId}
            onChange={onChange}
          />
        )
      })}
    </div>
  )
}

export default Availabilities
