import React from 'react'
import {Redirect, useLocation} from "react-router-dom"
import queryString from "query-string"
import ErrorMessage from '../../components/ErrorMessage'

interface IFailureParams {
  errorMessage: string
}

function Failure() {
  const location = useLocation()

  const params = queryString.parse(location.search) as unknown as IFailureParams

  if (!params) {
    return <Redirect to="/"/>
  }

  return <ErrorMessage className="d-flex pt-3 mx-3 align-items-center justify-content-center"
                       error={params.errorMessage}/>
}

export default Failure
