import React, {useEffect} from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom"

import {QueryClient, QueryClientProvider} from "react-query"
import styles from './styles.module.css'
import Contact from "../pages/Contact"
import Product from "../pages/Product"
import Init from "../pages/Init"
import {AppStateProvider} from "../BookingWidgetProvider"
import Payment from "../pages/Payment"
import Failure from "../pages/Failure"
import Result from "../pages/Result"
import Travelers from "../pages/Travelers"
import Timeout from "../pages/Timeout"

const queryClientConfig = {
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
}

const queryClient = new QueryClient(queryClientConfig)

function Routing() {
  const location = useLocation()

  useEffect(() => {
    window.parent.postMessage({
      from: 'booking_widget',
      type: 'location_change',
    }, "*")
  }, [location])

  return (
    <Switch>
      <Route path="/product" exact>
        <Product/>
      </Route>

      <Route path="/contact" exact>
        <Contact/>
      </Route>

      <Route path="/travelers" exact>
        <Travelers/>
      </Route>

      <Route path="/payment" exact>
        <Payment/>
      </Route>

      <Route path="/error" exact>
        <Failure/>
      </Route>

      <Route path="/result" exact>
        <Result/>
      </Route>

      <Route path="/timeout" exact>
        <Timeout/>
      </Route>

      <Route path="/" exact>
        <Init/>
      </Route>
    </Switch>
  )
}

function App() {
  return (
    <div className={styles.app}>
      <AppStateProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Routing/>
          </Router>
        </QueryClientProvider>
      </AppStateProvider>
    </div>
  )
}

export default App
