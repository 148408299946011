import cx from "classnames"
import codes from 'country-calling-code'
import styles from './styles.module.css'
import {COUNTRY_HASH, parsePhone, stringifyPhone} from "./utils"

interface IProps {
  value: string
  onChange: (event: { target: { value: string } }) => void
  className: string
}

const PhoneInput = ({value, onChange, className}: IProps) => {
  const {
    phoneCode,
    countryCode,
    phoneNumber,
  } = parsePhone(value)

  return (
    <div className={cx(className, 'd-flex flex-row align-items-center', styles.wrapper)}>
      <select
        className={cx(styles.select)}
        value={countryCode}
        onChange={(event) => {
          const newCountryCode = event.target.value
          const newPhoneCode = COUNTRY_HASH[newCountryCode].phoneCode
          onChange({target: {value: stringifyPhone(newPhoneCode, newCountryCode, phoneNumber)}})
        }}
      >
        {codes.map(({isoCode2: countryCode}) => {
          const country = COUNTRY_HASH[countryCode]
          return (
            <option
              key={countryCode}
              value={countryCode}
            >
              {`${country.flagIcon} ${country.countryName}`}
            </option>
          )
        })}
      </select>
      <div className={styles.phoneCodeWrapper}>+{phoneCode}</div>
      <input
        className={cx('form-control', styles.input, styles.phoneNumber)}
        type="text"
        value={phoneNumber}
        onChange={(event) => {
          const newPhoneNumber = event.target.value
          onChange({target: {value: stringifyPhone(phoneCode, countryCode, newPhoneNumber)}})
        }}
        autoComplete="tel-national"
      />
    </div>
  )
}

export default PhoneInput
