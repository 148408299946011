import React from 'react'
import ErrorMessage from '../../components/ErrorMessage'
import Spinner from "../../components/Spinner"
import styles from './styles.module.css'
import {useLogic} from "./useLogic"

function Init() {
  const {
    settingsError
  } = useLogic()

  if (settingsError) {
    return <ErrorMessage className={styles.page} error={settingsError}/>
  }

  return (
    <div className={styles.page}>
      <Spinner/>
    </div>
  )
}

export default Init
