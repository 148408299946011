import React, {ChangeEvent, useState} from 'react'
import {range} from 'lodash'
import styles from './styles.module.css'

interface IProps {
  initialQuantity: number
  onQuantityChange: (qty: number) => void
}

function Counter({initialQuantity, onQuantityChange}: IProps) {

  const [quantity, setQuantity] = useState(initialQuantity)

  const updateQuantity = (event: ChangeEvent<HTMLSelectElement>) => {
    const quantity = parseInt(event.target.value, 10)
    setQuantity(quantity)
    onQuantityChange(quantity)
  }

  return <div className={styles.counter}>
    <select className={styles.select} value={quantity} onChange={updateQuantity}>
      {range(initialQuantity, 101).map((value) => {
        return <option key={value} value={value}>{value}</option>
      })}
    </select>
  </div>
}

export default Counter
