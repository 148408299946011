import {Field} from "../Contact/Fields"
import {InputState} from "../Contact/contactReducer"

type Action =
  { type: 'setField', fieldName: string, index: string, field: Field } |
  { type: 'highlight' }

interface ITravelerInputs {
  [fieldName: string]: InputState
}

export interface IAllInputs {
  [index: string]: ITravelerInputs
}

export class TravelersState {
  public readonly isValid: boolean
  public readonly isFilled: boolean
  public readonly inputs: IAllInputs

  constructor(inputs: IAllInputs) {
    this.inputs = inputs

    this.isValid = true
    this.isFilled = true

    for (const index in this.inputs) {
      for (const fieldName in this.inputs[index]) {
        if (!this.inputs[index][fieldName].isValid) {
          this.isValid = false
        }

        if (!this.inputs[index][fieldName].field.value) {
          this.isFilled = false
        }
      }
    }
  }
}

export function travelersReducer(travelersState: TravelersState, action: Action): TravelersState {
  if (action.type === "setField") {
    return new TravelersState({
      ...travelersState.inputs,
      [action.index]: {
        ...travelersState.inputs[action.index],
        [action.fieldName]: new InputState(action.field),
      },
    })
  }

  if (action.type === 'highlight') {
    const inputs: IAllInputs = {}

    for (const index in travelersState.inputs) {
      inputs[index] = {}
      for (const fieldName in travelersState.inputs[index]) {
        const input = travelersState.inputs[index][fieldName]
        inputs[index][fieldName] = new InputState(input.field, !input.isValid)
      }
    }

    return new TravelersState(inputs)
  }

  throw new Error('Incorrect action type')
}
