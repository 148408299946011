import React from 'react'
import styles from './styles.module.css'
import cx from "classnames"
import {faCircle} from "@fortawesome/free-regular-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

interface IProps {
  color: string
  text: string
}

function Legend({color, text}: IProps) {
  return <div className={cx(styles.legend, styles[color])}>
    <FontAwesomeIcon icon={faCircle}/>
    <div>{text}</div>
  </div>
}

export default Legend
