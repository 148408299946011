import React from 'react'
import styles from './styles.module.css'
import cx from "classnames"
import {useSettings} from "../../BookingWidgetProvider/context"

interface IProps {
  accepted: boolean
  onChange: (value: boolean) => void
}

function Acceptance({accepted, onChange}: IProps) {
  const settings = useSettings()
  return (
    <div className={styles.acceptance}>
      <input
        className={cx("form-check-input", styles.checkbox)}
        type="checkbox"
        checked={accepted}
        readOnly
        onClick={() => onChange(!accepted)}
      />
      <div>
        <span>I accept the </span>
        <a
          className={styles.link}
          href={settings.cancellationPolicyLink}
          target="_blank"
          rel="noreferrer"
        >cancellation policy</a>
        <span> and </span>
        <a
          className={styles.link}
          href={settings.bookingTermsLink}
          target="_blank"
          rel="noreferrer"
        >booking terms</a>
        <span> for this booking.</span>
      </div>
    </div>
  )
}

export default Acceptance
