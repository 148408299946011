import React from 'react'
import styles from './styles.module.css'
import moment from "moment"
import cx from "classnames"
import Legend from "../Legend"
import CalendarNavigation from "../CalendarNavigation"
import CalendarNavigationList from "../CalendarNavigationList"
import {range} from 'lodash'
import {AVAILABILITY_STATUSES, Entities} from "ventrata-utils"

export interface ICalendarDate {
  year: number
  month: number
  date: number
}

interface IProps {
  days: Entities.CalendarDay[]
  calendarDate: ICalendarDate
  onChangeCalendarDate: (date: ICalendarDate) => void
}

const fixInvalidDate = (date: ICalendarDate) => {
  const daysInMonth = moment({
    year: date.year,
    month: date.month,
  }).daysInMonth()

  return date.date <= daysInMonth && date.date > 0
    ? date
    : {...date, date: daysInMonth}
}

const incMonth = (current: ICalendarDate): ICalendarDate => {
  return current.month < 11
    ? fixInvalidDate({...current, month: current.month + 1})
    : fixInvalidDate({...current, month: 0, year: current.year + 1})
}

const decMonth = (current: ICalendarDate): ICalendarDate => {
  return current.month > 0
    ? fixInvalidDate({...current, month: current.month - 1})
    : fixInvalidDate({...current, month: 11, year: current.year - 1})
}

const getDayClass = (day: Entities.CalendarDay): string => {
  switch (day.status) {
    case AVAILABILITY_STATUSES.CLOSED:
      return 'closed'
    case AVAILABILITY_STATUSES.SOLD_OUT:
      return 'soldOut'
    case AVAILABILITY_STATUSES.FREESALE:
      return 'available'
    case AVAILABILITY_STATUSES.LIMITED:
      return 'limited'
    // TODO: wait for fixed API and check it is actual case
    case AVAILABILITY_STATUSES.AVAILABLE:
      return day.capacity > 10 ? 'available' : 'limited'
    default:
      return '-'
  }
}

const DOW = [
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
  'SUN',
]

function Calendar({days, calendarDate, onChangeCalendarDate}: IProps) {
  const currentDate = calendarDate.date === 0
    ? null
    : moment({
      year: calendarDate.year,
      month: calendarDate.month,
      date: calendarDate.date,
    })

  return (
    <div>
      <div className={styles.topNavbar}>
        <CalendarNavigation
          onClick={() => onChangeCalendarDate(decMonth(calendarDate))}
          direction="left"
        />
        <CalendarNavigationList
          month={calendarDate.month}
          year={calendarDate.year}
        />
        <CalendarNavigation
          onClick={() => onChangeCalendarDate(incMonth(calendarDate))}
          direction="right"
        />
      </div>
      <div className={styles.calendar}>
        {DOW.map((name) => {
          return (
            <div className={styles.dow} key={name}>{name}</div>
          )
        })}
        {range(1, moment(days[0].localDate).day() || 7).map((i) => <div key={i}/>)}
        {days.map((item) => {
          const itemDate = moment(item.localDate)
          const itemClass = getDayClass(item)

          return (
            <div
              key={item.localDate}
              className={cx(
                styles.date,
                styles[itemClass],
                {[styles.current]: itemDate.isSame(currentDate, 'date')},
              )}
              onClick={() => onChangeCalendarDate({...calendarDate, date: itemDate.date()})}
            >
              <div>{itemDate.date()}</div>
            </div>
          )
        })}
      </div>
      <div className={styles.legend}>
        <Legend color="green" text="Available"/>
        <Legend color="yellow" text="Limited"/>
        <Legend color="red" text="Sold Out"/>
      </div>
      <div className={styles.bottomNavbar}>
        <CalendarNavigation
          title={moment(decMonth(calendarDate)).format('MMMM')}
          className={styles.redLink}
          onClick={() => onChangeCalendarDate(decMonth(calendarDate))}
          direction="left"
        />
        <CalendarNavigation
          title={moment(incMonth(calendarDate)).format('MMMM')}
          className={styles.redLink}
          onClick={() => onChangeCalendarDate(incMonth(calendarDate))}
          direction="right"
        />
      </div>
    </div>
  )
}

export default Calendar
