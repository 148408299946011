import React from 'react'

interface IErrorProps {
  error: any
  className?: string
}

function Error({error, className}: IErrorProps) {
  const message = typeof error === 'string'
    ? error
    : error.response?.data.error?.message || 'Unknown error'

  return (
    <div className={className}>
      <div className="alert alert-danger w-100 text-center" role="alert">{message}</div>
    </div>
  )
}

export default Error
