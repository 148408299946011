import {useHistory, useLocation} from "react-router-dom"
import queryString from "query-string"
import {useQuery} from "react-query"
import api from "../../api"
import {AxiosError} from "axios"
import {useInputSetter, useSettingsSetter} from "../../BookingWidgetProvider/context"
import {IGetSettingsResponse} from "../../commonTypes"

interface IInputParams {
  product_id: string
  option_id: string
  config_code: string
}

export const useLogic = () => {
  const location = useLocation()
  const history = useHistory()
  const setInput = useInputSetter()
  const setSettings = useSettingsSetter()

  const {
    product_id: productId,
    option_id: optionId,
    config_code: configCode,
  } = queryString.parse(location.search) as unknown as IInputParams

  const {
    error: settingsError,
  } = useQuery<IGetSettingsResponse, AxiosError>(['getSettings', configCode], () => api.getSettings({configCode}),
    {
      enabled: Boolean(configCode),
      onSuccess: (settingsData) => {
        setInput({
          productId,
          optionId,
          configCode,
        })

        setSettings(settingsData.settings)

        history.push("/product")
      },
    },
  )

  return {
    settingsError,
  }
}
