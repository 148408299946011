import React from 'react'
import styles from './styles.module.css'

interface IProps {
  minAge: number
  maxAge: number
}

const getRestriction = (minAge: number, maxAge: number) => {
  return maxAge === 100
    ? `${minAge}+`
    : `${minAge}-${maxAge}`
}

function AgeRestrictions({minAge, maxAge}: IProps) {
  const text = getRestriction(minAge, maxAge)
  return <div className={styles.text}>{text}</div>
}

export default AgeRestrictions
