import React, {useCallback, useMemo, useState} from "react"
import {BookingInfo, Contact, Input, Product, Result, Settings, Traveler} from '../commonTypes'
import {
  AppState,
  AppContextValue,
} from "./types"

interface IProps {
  children: React.ReactNode
}

export const AppContext = React.createContext<AppContextValue | null>(null)

export const AppStateProvider = ({children}: IProps) => {
  const [state, setState] = useState<AppState>({})

  const setSettings = useCallback(
    (settings: Settings) => setState((prevState: AppState) => ({...prevState, settings})),
    []
  )

  const setInput = useCallback(
    (input: Input) => setState((prevState: AppState) => ({...prevState, input})),
    []
  )

  const setProduct = useCallback(
    (product: Product) => setState((prevState: AppState) => ({...prevState, product})),
    []
  )

  const setContact = useCallback(
    (contact: Contact) => setState((prevState: AppState) => ({...prevState, contact})),
    []
  )

  const setTravelers = useCallback(
    (travelers: Traveler[]) => setState((prevState: AppState) => ({...prevState, travelers})),
    []
  )

  const setResult = useCallback(
    (result: Result) => setState((prevState: AppState) => ({...prevState, result})),
    []
  )

  const setCountryCode = useCallback(
    (countryCode: string) => setState((prevState: AppState) => ({...prevState, countryCode})),
    []
  )

  const setBookingInfo = useCallback(
    (bookingInfo: BookingInfo) => setState((prevState: AppState) => ({...prevState, bookingInfo})),
    []
  )

  const appContextValue: AppContextValue = useMemo(() => {
    return {
      state,
      setters: {
        setSettings,
        setInput,
        setProduct,
        setContact,
        setResult,
        setTravelers,
        setCountryCode,
        setBookingInfo,
      }
    }
  }, [
    state,
    setSettings,
    setInput,
    setProduct,
    setContact,
    setResult,
    setTravelers,
    setCountryCode,
    setBookingInfo,
  ])

  if (!appContextValue) {
    return null
  }

  return (
    <AppContext.Provider value={appContextValue} >
      {children}
    </AppContext.Provider>
  )
}
