import axios, {AxiosResponse} from "axios"
import {
  ICreateBookingParams,
  ICreateBookingResponse,
  IGetAvailabilitiesParams,
  IGetAvailabilitiesResponse,
  IGetCalendarParams,
  IGetCalendarResponse, IGetCountryCodeResponse,
  IGetProductParams,
  IGetProductResponse,
  IGetResultParams,
  IGetResultResponse,
  IGetSettingsParams,
  IGetSettingsResponse,
  IConfirmBookingParams,
  IConfirmBookingResponse,
} from './commonTypes'

const getProduct = async (params: IGetProductParams): Promise<IGetProductResponse> => {
  const result = await axios.post<IGetProductParams, AxiosResponse<IGetProductResponse>>(`/api/getProduct`, params)
  return result.data
}

const getCalendar = async (params: IGetCalendarParams): Promise<IGetCalendarResponse> => {
  const result = await axios.post<IGetCalendarParams, AxiosResponse<IGetCalendarResponse>>(`/api/getCalendar`, params)
  return result.data
}

const getAvailabilities = async (params: IGetAvailabilitiesParams): Promise<IGetAvailabilitiesResponse> => {
  const result = await axios.post<IGetAvailabilitiesParams, AxiosResponse<IGetAvailabilitiesResponse>>(`/api/getAvailabilities`, params)
  return result.data
}

const getResult = async (params: IGetResultParams): Promise<IGetResultResponse> => {
  const result = await axios.post<IGetResultParams, AxiosResponse<IGetResultResponse>>('/api/getResult', params)
  return result.data
}

const confirmBooking = async (params: IConfirmBookingParams): Promise<IConfirmBookingResponse> => {
  const result = await axios.post<IConfirmBookingParams, AxiosResponse<IConfirmBookingResponse>>('/api/confirmBooking', params)
  return result.data
}

const getSettings = async (params: IGetSettingsParams): Promise<IGetSettingsResponse> => {
  const result = await axios.post<IGetSettingsParams, AxiosResponse<IGetSettingsResponse>>('/api/getSettings', params)
  return result.data
}

const getCountryCode = async (): Promise<IGetCountryCodeResponse> => {
  const result = await axios.post<void, AxiosResponse<IGetCountryCodeResponse>>('/api/getCountryCode')
  return result.data
}

const createBooking = async (params: ICreateBookingParams): Promise<ICreateBookingResponse> => {
  const result = await axios.post<ICreateBookingParams, AxiosResponse<ICreateBookingResponse>>('/api/createBooking', params)
  return result.data
}

const api = {
  getProduct,
  getCalendar,
  getAvailabilities,
  confirmBooking,
  getResult,
  getSettings,
  getCountryCode,
  createBooking,
}

export default api
