import React from 'react'
import styles from './styles.module.css'
import cx from "classnames"

interface IProps {
  onClick: () => void
  disabled?: boolean
  text: string
}

function ActionButton({onClick, disabled = false, text}: IProps) {
  return <button
    className={cx(styles.next, {[styles.disabled]: disabled})}
    onClick={() => onClick()}
  >{text}</button>
}

export default ActionButton
