import React from 'react'
import {Link} from 'react-router-dom'
import styles from './styles.module.css'
import cx from "classnames"
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

interface IProps {
  path: string
}

function EditLink({path}: IProps) {
  return <Link to={path} className={cx("text-warning", styles.link)}>
    <FontAwesomeIcon icon={faPencilAlt}/>
    <div>EDIT</div>
  </Link>
}

export default EditLink
