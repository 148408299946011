import styles from './styles.module.css'
import moment from 'moment'
import cx from 'classnames'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faClock} from '@fortawesome/free-regular-svg-icons'
import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'

type Props = {
  className?: string
  utcCreatedAt: string
}

const timeoutMs = moment.duration(30, 'minutes').asMilliseconds()

export const Timer = ({className, utcCreatedAt}: Props) => {
  const history = useHistory()
  const createdMs = new Date(utcCreatedAt).getTime()

  const [restTime, setRestTime] = useState<number | null>(null)

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nowMs = Date.now()
      const newRestTime = timeoutMs - (nowMs - createdMs)
      setRestTime(newRestTime > 0 ? newRestTime : 0)

      if (newRestTime <= 0) {
        clearInterval(intervalId)
        history.push('/timeout')
      }
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [createdMs, history])

  if (restTime === null) {
    return null
  }

  return (
    <div className={cx(styles.timer, className)}>
      <FontAwesomeIcon icon={faClock}/>
      <div className={styles.time}>{moment(restTime).format('mm:ss')}</div>
    </div>
  )
}
