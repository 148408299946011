import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'

const root = document.getElementById('root')!

const observer = new ResizeObserver(() => {
  window.parent.postMessage({
    from: 'booking_widget',
    type: 'height_change',
    height: root.offsetHeight,
  }, "*")
})

observer.observe(root)

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  root,
)
