import React from 'react'
import styles from './styles.module.css'
import {InputState} from "../../pages/Contact/contactReducer"
import cx from "classnames"
import Label from "../Label"

interface IProps {
  label?: string
  inputState: InputState
  onChange: (value: string) => void
  component?: React.ComponentType<any> | 'input'
}

function InputBlock({label, inputState, onChange, component: Component = 'input'}: IProps) {
  return (
    <div className={styles.block}>
      {label && <Label title={label} required/>}
      <Component
        type="text"
        className={cx("form-control", {'is-invalid': inputState.isHighlighted})}
        value={inputState.field.value}
        onChange={(event) => onChange(event.target.value)}
      />
      <div className="invalid-feedback">
        Incorrect value
      </div>
    </div>
  )
}

export default InputBlock
