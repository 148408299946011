import {parsePhone} from "../../components/PhoneInput/utils"

export class Field {
  constructor(
    public readonly value: string,
    private readonly validateFunc: (value: string) => boolean,
    private readonly normalizeFunc: (value: string) => string,
  ) {
  }

  normalize() {
    return this.normalizeFunc(this.value)
  }

  validate() {
    return this.validateFunc(this.value)
  }
}

export class NameField extends Field {
  constructor(value: string) {
    super(
      value,
      (value) => !!value.trim(),
      (value) => value.trim(),
    )
  }

  static new(value: string = '') {
    return new NameField(value)
  }
}

export class EmailField extends Field {
  constructor(value: string) {
    super(
      value,
      (value) => !!value.match(/\S+@\S+\.\S+/),
      (value) => value.trim(),
    )
  }

  static new(value: string = '') {
    return new EmailField(value)
  }
}

export class AgeField extends Field {
  constructor(value: string) {
    super(
      value,
      (value) => !!value.match(/^\s*\d\s*$|^\s*[1-9]\d\s*$/),
      (value) => value.trim(),
    )
  }

  static new(value: string = '') {
    return new AgeField(value)
  }
}

export class PhoneField extends Field {
  constructor(value: string) {
    super(
      value,
      (value) => {
        const {phoneNumber} = parsePhone(value)
        return !!phoneNumber.match(/^\s*[\d -]*(\(\d+\))?[\d -]+\d\s*$/)
      },
      (value) => {
        const {
          phoneCode,
          phoneNumber,
        } = parsePhone(value)
        return `+${phoneCode}${phoneNumber.replace(/[- ()]/g, '')}`
      },
    )
  }

  static new(value: string = '') {
    return new PhoneField(value)
  }
}

export class ConfirmedEmailField extends Field {
  constructor(email: string, confirmedEmail: string) {
    super(
      confirmedEmail,
      (confirmedEmail) => confirmedEmail.trim() === email.trim(),
      (confirmedEmail) => confirmedEmail.trim(),
    )
  }

  static new(email: string = '', confirmedEmail: string = '') {
    return new ConfirmedEmailField(email, confirmedEmail)
  }
}
