import React from 'react'
import styles from './styles.module.css'
import AgeRestrictions from "../AgeRestrictions"
import Counter from "../Counter"
import {Entities, getUnitName} from "ventrata-utils"

interface IProps {
  unit: Entities.Unit
  onQuantityChange: (qty: number) => void
  initialQuantity: number
}

function AgeUnit({unit, onQuantityChange, initialQuantity}: IProps) {
  return (
    <div className={styles.component}>
      <div className={styles.right}>
        <div className={styles.title}>{getUnitName(unit)}</div>
        <AgeRestrictions minAge={unit.restrictions.minAge} maxAge={unit.restrictions.maxAge}/>
        {unit.subtitle && <div className={styles.subtitle}>{unit.subtitle}</div>}
      </div>
      <div className={styles.left}>
        <div className={styles.free}>FREE</div>
        <Counter initialQuantity={initialQuantity} onQuantityChange={onQuantityChange}/>
      </div>
    </div>
  )
}

export default AgeUnit
