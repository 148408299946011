import React from 'react'
import styles from './styles.module.css'
import cx from "classnames"

interface IProps {
  title: string
  htmlFor?: string
  required?: boolean
}

function Label({title, htmlFor, required = false}: IProps) {
  return (
    <div className={styles.label}>
      <label htmlFor={htmlFor} className={styles.title}>{title}</label>
      {required && <span className={cx('text-danger', styles.asterisk)}>*</span>}
    </div>
  )
}

export default Label
