import React from 'react'
import styles from './styles.module.css'

interface IProps {
  title: string
}

function TourTitle({title}: IProps) {
  return <div className={styles.title}>{title}</div>
}

export default TourTitle
