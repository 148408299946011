import {Entities} from 'ventrata-utils'

type Action =
  { type: 'initialize', counters: Entities.CombinedUnitCounter[] } |
  { type: 'setUnit', counter: Entities.CombinedUnitCounter }

export function unitsReducer(unitCounters: Entities.CombinedUnitCounter[], action: Action): Entities.CombinedUnitCounter[] {
  if (action.type === "initialize") {
    return action.counters
  }

  if (action.type === 'setUnit') {
    const counters = [...unitCounters]
    counters.find((item) => item.type === action.counter.type)!.quantity = action.counter.quantity
    return counters
  }

  throw new Error('Incorrect action type')
}
