import React from 'react'
import styles from './styles.module.css'
import moment from "moment"
import {capitalize} from 'lodash'
import {Info} from "../Info"
import {DEFAULT_OPTION} from "ventrata-utils"
import {Product} from '../../commonTypes'

interface IProps {
  product: Product
}

function ShoppingCard({product}: IProps) {
  if (!product) {
    return null
  }

  return (
    <Info>
      <div>{product.productName}</div>
      {product.optionName !== DEFAULT_OPTION && <div>{product.optionName}</div>}
      <div className={styles.date}>{moment.parseZone(product.availabilityId).format('MMM DD, YYYY hh:mm A')}</div>
      <div className={styles.units}>
        {product.units
          .filter((unit) => unit.quantity !== 0)
          .map((unit) => <div key={unit.type}>{unit.quantity} {capitalize(unit.type)}</div>)}
      </div>
    </Info>
  )
}

export default ShoppingCard
