import codes from 'country-calling-code'
import React, {useEffect, useState} from "react"
import Label from "../Label"
import {COUNTRY_HASH} from "../PhoneInput/utils"
import {useCountryCode} from "../../BookingWidgetProvider/context"
import {BillingAddress} from '../../commonTypes'
import cx from "classnames"
import styles from './styles.module.css'

export interface IBillingInfo {
  billingAddress: BillingAddress | null
  isComplete: boolean
}

interface IProps {
  onChange: (billingInfo: IBillingInfo) => void
}

export const BillingInfo = ({onChange}: IProps) => {
  const countryCode = useCountryCode()
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState(countryCode || 'US')

  useEffect(() => {
    const billingAddress = {
      addressLine1,
      addressLine2,
      city,
      state,
      country,
    }

    const isComplete = Boolean(addressLine1 && city && state && country)

    onChange({
      billingAddress,
      isComplete,
    })
  }, [onChange, addressLine1, addressLine2, city, state, country])

  return (
    <div className="d-flex flex-column gap-2">
      <div>
        <Label htmlFor="addressLine1" title="Address Line 1" required />
        <input
          id="addressLine1"
          className="form-control"
          type="text"
          value={addressLine1}
          onChange={(event) => setAddressLine1(event.target.value)}
          autoComplete="address-line1"
        />
      </div>
      <div>
        <Label htmlFor="addressLine2" title="Address Line 2"/>
        <input
          id="addressLine2"
          className="form-control"
          type="text"
          value={addressLine2}
          onChange={(event) => setAddressLine2(event.target.value)}
          autoComplete="address-line2"
        />
      </div>
      <div>
        <Label htmlFor="city" title="City" required />
        <input
          id="city"
          className="form-control"
          type="text"
          value={city}
          onChange={(event) => setCity(event.target.value)}
          autoComplete="address-level2"
        />
      </div>
      <div>
        <Label htmlFor="state" title="State" required />
        <input
          id="state"
          className="form-control"
          type="text"
          value={state}
          onChange={(event) => setState(event.target.value)}
          autoComplete="address-level1"
        />
      </div>
      <div>
        <Label htmlFor="country" title="Country" required />
        <select
          id="country"
          className={cx("form-control", styles.select)}
          value={country}
          onChange={(event) => setCountry(event.target.value)}
          placeholder="Country"
          autoComplete="country"
        >
          {codes.map(({isoCode2: countryCode}) => {
            const country = COUNTRY_HASH[countryCode]
            return (
              <option
                key={countryCode}
                value={countryCode}
              >
                {`${country.flagIcon} ${country.countryName}`}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  )
}
