import React, {useState} from 'react'
import {useQuery} from 'react-query'
import {AxiosError} from 'axios'
import api from "../../api"
import BookingInfo from "../../components/BookingInfo"
import Spinner from "../../components/Spinner"
import ErrorMessage from "../../components/ErrorMessage"
import styles from './styles.module.css'
import {Entities} from "ventrata-utils"
import {useInput, useResult} from "../../BookingWidgetProvider/context"
import {IGetResultResponse} from "../../commonTypes"

function Result() {
  const input = useInput()
  const result = useResult()

  const [booking, setBooking] = useState<Entities.Booking | null>(null)

  const {bookingUuid} = result
  const {configCode} = input

  const {
    error: resultError,
    isLoading: bookingLoading,
  } = useQuery<IGetResultResponse, AxiosError>(['getBooking', bookingUuid], () => api.getResult({
    bookingUuid,
    configCode,
  }), {
    onSuccess: (resultData) => {
      setBooking(resultData.booking)

      if (!resultData.redirect) {
        return
      }

      setTimeout(() => {
        if (!resultData.redirect) {
          return
        }
        window.parent.location.href = resultData.redirect.url
      }, resultData.redirect.delaySec * 1000)
    },
  })

  const error = resultError
  const loading = bookingLoading

  if (error) {
    return <ErrorMessage className={styles.input} error={error}/>
  }

  if (loading || !booking) {
    return <Spinner/>
  }

  return (
    <div className={styles.input}>
      <h5 className="text-center fw-bold">Congrats, your booking has been successfully reserved</h5>
      <BookingInfo
        booking={booking}
      />
      <div className="alert alert-primary p-3 mb-0">
        If you didn't receive a booking confirmation email, please check your spam folder. If you don't see it there, please contact us.
      </div>
    </div>
  )
}

export default Result
