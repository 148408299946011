import React from 'react'
import {Traveler} from '../../commonTypes'
import {Info} from "../Info"

interface IProps {
  travelers: Traveler[]
}

function TravelersInfo({travelers}: IProps) {
  return (
    <Info>
      {travelers.map((traveler) => {
        const fullName = `${traveler.firstName} ${traveler.lastName}, ${traveler.age} y.o.`
        return <div key={fullName}>{fullName}</div>
      })}
    </Info>
  )
}

export default TravelersInfo
